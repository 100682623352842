

































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const AppModule = namespace("AppModule");
@Component
export default class StepperActions extends Vue {
  @AppModule.Getter
  public smallScreen!: string;
  //
  //
  // PROPS
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: false }) readonly first!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: "btn-continue" }) readonly btnType!: string;
  @Prop() readonly icon!: string;
  //
  //
  // DATA
  private disabledOnClick = false;
  //
  //
  // METHODS
  onBack() {
    this.disabledOnClick = true;
    this.$emit("back");
    setTimeout(() => {
      this.disabledOnClick = false;
    }, 100);
  }
  onContinue() {
    this.disabledOnClick = true;
    this.$emit("continue");
    setTimeout(() => {
      this.disabledOnClick = false;
    }, 100);
  }
}
