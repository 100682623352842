





























































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Field, StepField, IconsType } from "@/types";
import { mdiDownloadOutline, mdiUploadOutline } from "@mdi/js";
import StepperActions from "@/components/Stepper/StepperActions.vue";
@Component({
  components: {
    StepperActions,
    StepperConsentUpload: () =>
      import("@/components/Stepper/StepperConsentUpload.vue"),
    StepperConsentDownload: () =>
      import("@/components/Stepper/StepperConsentDownload.vue"),
    StepperTemplateUpload: () =>
      import("@/components/Stepper/StepperTemplateUpload.vue"),
    StepperDocumentsUpload: () =>
      import("@/components/Stepper/StepperDocumentsUpload.vue"),
    StepperSubmit: () => import("@/components/Stepper/StepperSubmit.vue"),
    StepperEdit: () => import("@/components/Stepper/StepperEdit.vue"),
    StepperField: () => import("@/components/Stepper/StepperField.vue"),
    StepperTemplateDownload: () =>
      import("@/components/Stepper/StepperTemplateDownload.vue")
  }
})
export default class Stepper extends Vue {
  //
  //
  // PROPS
  @Prop() readonly itemId!: number;
  @Prop() readonly itemName!: string;
  @Prop() readonly documentDescription!: string;
  @Prop() readonly needAdditionalDocuments!: boolean;
  @Prop() readonly consentRequired!: boolean;
  @Prop() readonly applicationFields!: Array<Field>;
  @Prop({ default: false }) readonly editStepper!: boolean;
  //
  //
  // COMPUTED
  get getStepTemplateDownload() {
    return this.stepFields.length + 1;
  }
  get getStepTemplateUpload() {
    return this.getStepTemplateDownload + 1;
  }
  get getStepConsentDownload() {
    return this.getStepTemplateUpload + 1;
  }
  get getStepConsentUpload() {
    return this.getStepConsentDownload + 1;
  }
  get getStepDocumentsUpload() {
    return (
      (this.consentRequired
        ? this.getStepConsentUpload
        : this.getStepTemplateUpload) + 1
    );
  }
  get getStepSubmit() {
    return this.getStepDocumentsUpload + 1;
  }
  //
  //
  // DATA
  private icons: IconsType = {
    download: mdiDownloadOutline,
    upload: mdiUploadOutline
  };
  private currentStep = 0;
  private stepFields: Array<StepField> = [];
  private submit = {
    error: false,
    complete: false
  };
  private templateDownload = {
    error: false,
    complete: false
  };
  private consentDownload = {
    error: false,
    complete: false
  };
  private templateUpload = {
    error: false,
    complete: false
  };
  private documentsUpload = {
    error: false,
    complete: false
  };
  private consentUpload = {
    error: false,
    complete: false
  };
  //
  //
  // METHODS
  onBack() {
    this.currentStep -= 1;
  }
  onContinue() {
    this.currentStep += 1;
    let step = this.$refs[`step-${this.currentStep}`];
    if (step) {
      if (Array.isArray(step) && step[0]) step = step[0];
      setTimeout(() => this.$vuetify.goTo(step as HTMLElement), 600);
    }
  }
  onContinueField(index: number) {
    this.stepFields[index].complete = true;
    this.onContinue();
  }
  onContinueTemplateDownload(payload: boolean) {
    this.templateDownload.complete = payload;
    this.templateDownload.error = !payload;
    if (payload) this.onContinue();
  }
  onContinueConsentDownload(payload: boolean) {
    this.consentDownload.complete = payload;
    this.consentDownload.error = !payload;
    if (payload) this.onContinue();
  }
  onBackTemplateUpload() {
    this.$store.commit("ItemModule/SIGNED_TEMPLATE", null);
    this.onBack();
  }
  onBackConsentUpload() {
    this.$store.commit("ItemModule/SIGNED_CONSENT", null);
    this.onBack();
  }
  onContinueTemplateUpload(payload: boolean) {
    this.templateUpload.complete = payload;
    this.templateUpload.error = !payload;
    if (payload) this.onContinue();
  }
  onContinueConsentUpload(payload: boolean) {
    this.consentUpload.complete = payload;
    this.consentUpload.error = !payload;
    if (payload) this.onContinue();
  }
  onContinueDocumentsUpload(payload: boolean) {
    this.documentsUpload.complete = payload;
    this.documentsUpload.error = !payload;
    if (payload) this.onContinue();
  }
  onSubmitted(payload: boolean) {
    this.submit.complete = payload;
    this.submit.error = !payload;
    if (payload) this.$emit("complete");
  }
  setCompleted(step: number) {
    for (let i = 0; i < Math.min(this.stepFields.length, step); i++)
      this.stepFields[i].complete = true;
    if (step >= this.getStepTemplateDownload)
      this.templateDownload.complete = true;
    if (step >= this.getStepTemplateUpload) this.templateUpload.complete = true;
    if (step >= this.getStepConsentDownload)
      this.consentDownload.complete = true;
    if (step >= this.getStepConsentUpload) this.consentUpload.complete = true;
    if (step >= this.getStepDocumentsUpload)
      this.documentsUpload.complete = true;
    if (step >= this.getStepSubmit) this.submit.complete = true;
  }
  //
  //
  // LIFECYCLE
  async created() {
    this.applicationFields.forEach(field => {
      this.stepFields.push({
        ...field,
        error: false,
        complete: false
      });
    });
    if (this.editStepper) {
      await this.$store.dispatch("ApplicationModule/preload");
    }
  }
  mounted() {
    setTimeout(() => {
      const step = localStorage.getItem(`cached-step-${this.itemId}`);
      localStorage.removeItem(`cached-step-${this.itemId}`);
      if (step) this.currentStep = Number(step);
      else this.currentStep = 1;

      this.setCompleted(this.currentStep - 1);
    }, 200);
  }
  beforeDestroy() {
    if (
      this.currentStep &&
      this.currentStep !== 1 &&
      this.currentStep !== this.getStepSubmit
    )
      localStorage.setItem(
        `cached-step-${this.itemId}`,
        JSON.stringify(this.currentStep)
      );
  }
}
