var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticClass:"elevation-0",attrs:{"vertical":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_vm._l((_vm.stepFields),function(field,index){return [_c('v-stepper-step',{key:("step-field-" + index),ref:("step-" + (index + 1)),refInFor:true,attrs:{"step":index + 1,"complete":field.complete}},[_vm._v(" "+_vm._s(field.name)+" ")]),_c('v-stepper-content',{key:("content-field-" + index),attrs:{"step":index + 1}},[_c('StepperField',{attrs:{"field":field,"autofocus":_vm.currentStep === index + 1},on:{"continue":function($event){return _vm.onContinueField(index)}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var disabled = ref.disabled;
return [_c('StepperActions',{attrs:{"disabled":disabled,"first":index === 0},on:{"back":_vm.onBack,"continue":function($event){return _vm.onContinueField(index)}}})]}}],null,true)})],1)]}),_c('v-stepper-step',{key:"step-template-download",ref:("step-" + _vm.getStepTemplateDownload),attrs:{"step":_vm.getStepTemplateDownload,"complete":_vm.templateDownload.complete,"rules":[function () { return !_vm.templateDownload.error; }]}},[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.label-download-template"))+" "),(_vm.templateDownload.error)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.error"))+" ")]):_vm._e()]),_c('v-stepper-content',{key:"content-template-download",attrs:{"step":_vm.getStepTemplateDownload}},[_c('StepperTemplateDownload',{attrs:{"item-id":_vm.itemId,"item-name":_vm.itemName},on:{"continue":_vm.onContinueTemplateDownload},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var on = ref.on;
var loading = ref.loading;
return [_c('StepperActions',{attrs:{"loading":loading,"btn-type":"btn-download","icon":_vm.icons.download},on:{"back":_vm.onBack,"continue":on}})]}}])})],1),_c('v-stepper-step',{key:"step-template-upload",ref:("step-" + _vm.getStepTemplateUpload),attrs:{"step":_vm.getStepTemplateUpload,"complete":_vm.templateUpload.complete,"rules":[function () { return !_vm.templateUpload.error; }]}},[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.label-upload"))+" "),(_vm.templateUpload.error)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.error"))+" ")]):_vm._e()]),_c('v-stepper-content',{key:"content-template-upload",attrs:{"step":_vm.getStepTemplateUpload}},[_c('StepperTemplateUpload',{on:{"error":function($event){return _vm.onContinueTemplateUpload(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var disabled = ref.disabled;
return [_c('StepperActions',{attrs:{"disabled":disabled},on:{"back":_vm.onBackTemplateUpload,"continue":function($event){return _vm.onContinueTemplateUpload(true)}}})]}}])})],1),(_vm.consentRequired)?_c('v-stepper-step',{key:"step-consent-download",ref:("step-" + _vm.getStepConsentDownload),attrs:{"step":_vm.getStepConsentDownload,"complete":_vm.consentDownload.complete,"rules":[function () { return !_vm.consentDownload.error; }]}},[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.label-download-consent"))+" "),(_vm.consentDownload.error)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.error"))+" ")]):_vm._e()]):_vm._e(),(_vm.consentRequired)?_c('v-stepper-content',{key:"content-consent-download",attrs:{"step":_vm.getStepConsentDownload}},[_c('StepperConsentDownload',{attrs:{"item-id":_vm.itemId,"item-name":_vm.itemName},on:{"continue":_vm.onContinueConsentDownload},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var on = ref.on;
var loading = ref.loading;
return [_c('StepperActions',{attrs:{"loading":loading,"btn-type":"btn-download","icon":_vm.icons.download},on:{"back":_vm.onBack,"continue":on}})]}}],null,false,3301140905)})],1):_vm._e(),(_vm.consentRequired)?_c('v-stepper-step',{key:"step-consent-upload",ref:("step-" + _vm.getStepConsentUpload),attrs:{"step":_vm.getStepConsentUpload,"complete":_vm.consentUpload.complete,"rules":[function () { return !_vm.consentUpload.error; }]}},[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.label-upload-consent"))+" "),(_vm.consentUpload.error)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.error"))+" ")]):_vm._e()]):_vm._e(),(_vm.consentRequired)?_c('v-stepper-content',{key:"content-consent-upload",attrs:{"step":_vm.getStepConsentUpload}},[_c('StepperConsentUpload',{on:{"error":function($event){return _vm.onContinueConsentUpload(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var disabled = ref.disabled;
return [_c('StepperActions',{attrs:{"disabled":disabled},on:{"back":_vm.onBackConsentUpload,"continue":function($event){return _vm.onContinueConsentUpload(true)}}})]}}],null,false,85136422)})],1):_vm._e(),_c('v-stepper-step',{key:"step-documents-upload",ref:("step-" + _vm.getStepDocumentsUpload),attrs:{"step":_vm.getStepDocumentsUpload,"complete":_vm.documentsUpload.complete,"rules":[function () { return !_vm.documentsUpload.error; }]}},[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.label-upload-docs"))+" "),(_vm.documentsUpload.error)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.error"))+" ")]):_vm._e()]),_c('v-stepper-content',{key:"content-documents-upload",attrs:{"step":_vm.getStepDocumentsUpload}},[_c('StepperDocumentsUpload',{attrs:{"description":_vm.documentDescription,"need-additional-documents":_vm.needAdditionalDocuments},on:{"error":function($event){return _vm.onContinueDocumentsUpload(false)}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var disabled = ref.disabled;
return [_c('StepperActions',{attrs:{"disabled":disabled},on:{"back":_vm.onBack,"continue":function($event){return _vm.onContinueDocumentsUpload(true)}}})]}}])})],1),_c('v-stepper-step',{key:"step-submit",ref:("step-" + _vm.getStepSubmit),attrs:{"step":_vm.getStepSubmit,"complete":_vm.submit.complete,"rules":[function () { return !_vm.submit.error; }]}},[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.label-submit"))+" "),(_vm.submit.error)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("pages.item.stepper.error"))+" ")]):_vm._e()]),_c('v-stepper-content',{key:"content-submit",attrs:{"step":_vm.getStepSubmit}},[(_vm.editStepper)?_c('StepperEdit',{on:{"submitted":_vm.onSubmitted},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var on = ref.on;
var loading = ref.loading;
return [_c('StepperActions',{attrs:{"loading":loading,"btn-type":"btn-submit"},on:{"back":_vm.onBack,"continue":on}})]}}],null,false,1365555222)}):_c('StepperSubmit',{on:{"submitted":_vm.onSubmitted},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var on = ref.on;
var loading = ref.loading;
return [_c('StepperActions',{attrs:{"loading":loading,"btn-type":"btn-submit"},on:{"back":_vm.onBack,"continue":on}})]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }